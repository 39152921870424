.steps {
  .dropdown-toggle::after {
    color: $lighten-standard-fontcolor;
    transition: 0.5s;
    font-size: 16px;
  }

  .dropdown-toggle[aria-expanded='true']:after {
    transform: rotate(180deg);
  }
  .collapsing {
    transition: 0.5s;
  }
}

.files,
.stage {
  a {
    &[data-toggle='collapse'] {
      position: relative;
      width: 100% !important;
      display: block;
    }
  }
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 45%;
    left: 10px;
    color: $lighten-standard-fontcolor;
    transition: 0.5s;
    font-size: 22px;
  }
  .dropdown-toggle[aria-expanded='true']:after {
    transform: rotate(180deg);
  }
  .collapsing {
    transition: 0.5s;
  }
}
