.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-0dot5 {
  margin: 0.143rem !important;
}

.mt-0dot5,
.my-0dot5 {
  margin-top: 0.143rem !important;
}

.mr-0dot5,
.mx-0dot5 {
  margin-right: 0.143rem !important;
}

.mb-0dot5,
.my-0dot5 {
  margin-bottom: 0.143rem !important;
}

.ml-0dot5,
.mx-0dot5 {
  margin-left: 0.143rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.m-10 {
  margin: 4.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 4.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 4.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 4.5rem !important;
}

.m-11 {
  margin: 5rem !important;
}

.mt-11,
.my-11 {
  margin-top: 5rem !important;
}

.mr-11,
.mx-11 {
  margin-right: 5rem !important;
}

.mb-11,
.my-11 {
  margin-bottom: 5rem !important;
}

.ml-11,
.mx-11 {
  margin-left: 5rem !important;
}

.m-12 {
  margin: 5.5rem !important;
}

.mt-12,
.my-12 {
  margin-top: 5.5rem !important;
}

.mr-12,
.mx-12 {
  margin-right: 5.5rem !important;
}

.mb-12,
.my-12 {
  margin-bottom: 5.5rem !important;
}

.ml-12,
.mx-12 {
  margin-left: 5.5rem !important;
}

.m-13 {
  margin: 6rem !important;
}

.mt-13,
.my-13 {
  margin-top: 6rem !important;
}

.mr-13,
.mx-13 {
  margin-right: 6rem !important;
}

.mb-13,
.my-13 {
  margin-bottom: 6rem !important;
}

.ml-13,
.mx-13 {
  margin-left: 6rem !important;
}

.m-14 {
  margin: 6.5rem !important;
}

.mt-14,
.my-14 {
  margin-top: 6.5rem !important;
}

.mr-14,
.mx-14 {
  margin-right: 6.5rem !important;
}

.mb-14,
.my-14 {
  margin-bottom: 6.5rem !important;
}

.ml-14,
.mx-14 {
  margin-left: 6.5rem !important;
}

.m-15 {
  margin: 7rem !important;
}

.mt-15,
.my-15 {
  margin-top: 7rem !important;
}

.mr-15,
.mx-15 {
  margin-right: 7rem !important;
}

.mb-15,
.my-15 {
  margin-bottom: 7rem !important;
}

.ml-15,
.mx-15 {
  margin-left: 7rem !important;
}

.m-16 {
  margin: 8rem !important;
}

.mt-16,
.my-16 {
  margin-top: 8rem !important;
}

.mr-16,
.mx-16 {
  margin-right: 8rem !important;
}

.mb-16,
.my-16 {
  margin-bottom: 8rem !important;
}

.ml-16,
.mx-16 {
  margin-left: 8rem !important;
}

.m-17 {
  margin: 9rem !important;
}

.mt-17,
.my-17 {
  margin-top: 9rem !important;
}

.mr-17,
.mx-17 {
  margin-right: 9rem !important;
}

.mb-17,
.my-17 {
  margin-bottom: 9rem !important;
}

.ml-17,
.mx-17 {
  margin-left: 9rem !important;
}

.m-18 {
  margin: 10rem !important;
}

.mt-18,
.my-18 {
  margin-top: 10rem !important;
}

.mr-18,
.mx-18 {
  margin-right: 10rem !important;
}

.mb-18,
.my-18 {
  margin-bottom: 10rem !important;
}

.ml-18,
.mx-18 {
  margin-left: 10rem !important;
}

.m-19 {
  margin: 10.6rem !important;
}

.mt-19,
.my-19 {
  margin-top: 10.6rem !important;
}

.mr-19,
.mx-19 {
  margin-right: 10.6rem !important;
}

.mb-19,
.my-19 {
  margin-bottom: 10.6rem !important;
}

.ml-19,
.mx-19 {
  margin-left: 10.6rem !important;
}

.m-20 {
  margin: 12.4rem !important;
}

.mt-20,
.my-20 {
  margin-top: 12.4rem !important;
}

.mr-20,
.mx-20 {
  margin-right: 12.4rem !important;
}

.mb-20,
.my-20 {
  margin-bottom: 12.4rem !important;
}

.ml-20,
.mx-20 {
  margin-left: 12.4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-0dot5 {
  padding: 0.143rem !important;
}

.pt-0dot5,
.py-0dot5 {
  padding-top: 0.143rem !important;
}

.pr-0dot5,
.px-0dot5 {
  padding-right: 0.143rem !important;
}

.pb-0dot5,
.py-0dot5 {
  padding-bottom: 0.143rem !important;
}

.pl-0dot5,
.px-0dot5 {
  padding-left: 0.143rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.p-10 {
  padding: 4.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 4.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 4.5rem !important;
}

.p-11 {
  padding: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.pr-11,
.px-11 {
  padding-right: 5rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pl-11,
.px-11 {
  padding-left: 5rem !important;
}

.p-12 {
  padding: 5.5rem !important;
}

.pt-12,
.py-12 {
  padding-top: 5.5rem !important;
}

.pr-12,
.px-12 {
  padding-right: 5.5rem !important;
}

.pb-12,
.py-12 {
  padding-bottom: 5.5rem !important;
}

.pl-12,
.px-12 {
  padding-left: 5.5rem !important;
}

.p-13 {
  padding: 6rem !important;
}

.pt-13,
.py-13 {
  padding-top: 6rem !important;
}

.pr-13,
.px-13 {
  padding-right: 6rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 6rem !important;
}

.pl-13,
.px-13 {
  padding-left: 6rem !important;
}

.p-14 {
  padding: 6.5rem !important;
}

.pt-14,
.py-14 {
  padding-top: 6.5rem !important;
}

.pr-14,
.px-14 {
  padding-right: 6.5rem !important;
}

.pb-14,
.py-14 {
  padding-bottom: 6.5rem !important;
}

.pl-14,
.px-14 {
  padding-left: 6.5rem !important;
}

.p-15 {
  padding: 7rem !important;
}

.pt-15,
.py-15 {
  padding-top: 7rem !important;
}

.pr-15,
.px-15 {
  padding-right: 7rem !important;
}

.pb-15,
.py-15 {
  padding-bottom: 7rem !important;
}

.pl-15,
.px-15 {
  padding-left: 7rem !important;
}

.p-16 {
  padding: 8rem !important;
}

.pt-16,
.py-16 {
  padding-top: 8rem !important;
}

.pr-16,
.px-16 {
  padding-right: 8rem !important;
}

.pb-16,
.py-16 {
  padding-bottom: 8rem !important;
}

.pl-16,
.px-16 {
  padding-left: 8rem !important;
}

.p-17 {
  padding: 9rem !important;
}

.pt-17,
.py-17 {
  padding-top: 9rem !important;
}

.pr-17,
.px-17 {
  padding-right: 9rem !important;
}

.pb-17,
.py-17 {
  padding-bottom: 9rem !important;
}

.pl-17,
.px-17 {
  padding-left: 9rem !important;
}

.p-18 {
  padding: 10rem !important;
}

.pt-18,
.py-18 {
  padding-top: 10rem !important;
}

.pr-18,
.px-18 {
  padding-right: 10rem !important;
}

.pb-18,
.py-18 {
  padding-bottom: 10rem !important;
}

.pl-18,
.px-18 {
  padding-left: 10rem !important;
}

.p-19 {
  padding: 10.6rem !important;
}

.pt-19,
.py-19 {
  padding-top: 10.6rem !important;
}

.pr-19,
.px-19 {
  padding-right: 10.6rem !important;
}

.pb-19,
.py-19 {
  padding-bottom: 10.6rem !important;
}

.pl-19,
.px-19 {
  padding-left: 10.6rem !important;
}

.p-20 {
  padding: 12.4rem !important;
}

.pt-20,
.py-20 {
  padding-top: 12.4rem !important;
}

.pr-20,
.px-20 {
  padding-right: 12.4rem !important;
}

.pb-20,
.py-20 {
  padding-bottom: 12.4rem !important;
}

.pl-20,
.px-20 {
  padding-left: 12.4rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-n10 {
  margin: -4.5rem !important;
}

.mt-n10,
.my-n10 {
  margin-top: -4.5rem !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -4.5rem !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -4.5rem !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -4.5rem !important;
}

.m-n11 {
  margin: -5rem !important;
}

.mt-n11,
.my-n11 {
  margin-top: -5rem !important;
}

.mr-n11,
.mx-n11 {
  margin-right: -5rem !important;
}

.mb-n11,
.my-n11 {
  margin-bottom: -5rem !important;
}

.ml-n11,
.mx-n11 {
  margin-left: -5rem !important;
}

.m-n12 {
  margin: -5.5rem !important;
}

.mt-n12,
.my-n12 {
  margin-top: -5.5rem !important;
}

.mr-n12,
.mx-n12 {
  margin-right: -5.5rem !important;
}

.mb-n12,
.my-n12 {
  margin-bottom: -5.5rem !important;
}

.ml-n12,
.mx-n12 {
  margin-left: -5.5rem !important;
}

.m-n13 {
  margin: -6rem !important;
}

.mt-n13,
.my-n13 {
  margin-top: -6rem !important;
}

.mr-n13,
.mx-n13 {
  margin-right: -6rem !important;
}

.mb-n13,
.my-n13 {
  margin-bottom: -6rem !important;
}

.ml-n13,
.mx-n13 {
  margin-left: -6rem !important;
}

.m-n14 {
  margin: -6.5rem !important;
}

.mt-n14,
.my-n14 {
  margin-top: -6.5rem !important;
}

.mr-n14,
.mx-n14 {
  margin-right: -6.5rem !important;
}

.mb-n14,
.my-n14 {
  margin-bottom: -6.5rem !important;
}

.ml-n14,
.mx-n14 {
  margin-left: -6.5rem !important;
}

.m-n15 {
  margin: -7rem !important;
}

.mt-n15,
.my-n15 {
  margin-top: -7rem !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -7rem !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -7rem !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -7rem !important;
}

.m-n16 {
  margin: -8rem !important;
}

.mt-n16,
.my-n16 {
  margin-top: -8rem !important;
}

.mr-n16,
.mx-n16 {
  margin-right: -8rem !important;
}

.mb-n16,
.my-n16 {
  margin-bottom: -8rem !important;
}

.ml-n16,
.mx-n16 {
  margin-left: -8rem !important;
}

.m-n17 {
  margin: -9rem !important;
}

.mt-n17,
.my-n17 {
  margin-top: -9rem !important;
}

.mr-n17,
.mx-n17 {
  margin-right: -9rem !important;
}

.mb-n17,
.my-n17 {
  margin-bottom: -9rem !important;
}

.ml-n17,
.mx-n17 {
  margin-left: -9rem !important;
}

.m-n18 {
  margin: -10rem !important;
}

.mt-n18,
.my-n18 {
  margin-top: -10rem !important;
}

.mr-n18,
.mx-n18 {
  margin-right: -10rem !important;
}

.mb-n18,
.my-n18 {
  margin-bottom: -10rem !important;
}

.ml-n18,
.mx-n18 {
  margin-left: -10rem !important;
}

.m-n19 {
  margin: -10.6rem !important;
}

.mt-n19,
.my-n19 {
  margin-top: -10.6rem !important;
}

.mr-n19,
.mx-n19 {
  margin-right: -10.6rem !important;
}

.mb-n19,
.my-n19 {
  margin-bottom: -10.6rem !important;
}

.ml-n19,
.mx-n19 {
  margin-left: -10.6rem !important;
}

.m-n20 {
  margin: -12.4rem !important;
}

.mt-n20,
.my-n20 {
  margin-top: -12.4rem !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -12.4rem !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -12.4rem !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -12.4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
