.active {
  background: lighten($bg-color, 5%);
  color: $active-standard-fontcolor !important;
}
.page-header {
  height: 46px !important;
  line-height: 46px !important;
  width: 100%;
  background: $layout-bg-color;
  border-bottom: 1px solid black;
  padding-top: 10px;
  padding-right: 45px;
  color: $dark;
  button {
    color: $standard-fontcolor;
    outline: 0;
    padding: 0px;
    i {
      position: relative;
      top: 3px;
    }
  }
}
.section-header {
  height: 45px;
  line-height: 45px;
  width: 100%;
  background: $layout-bg-color;
  padding: 4px 10px;
  border-bottom: 1px solid black;
  button,
  .btn {
    padding: 0px;
    i {
      position: relative;
      top: 3px;
    }
    :hover {
      color: $cyan;
    }
    :focus {
      outline: none !important;
    }
  }
}

.border-radius-25 {
  border-radius: 25px !important;
}
.border-radius-20 {
  border-radius: 20px !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  box-shadow: none;
}

.mat-menu-content {
  padding: 0px 0px;
  min-width: 170px !important;
  margin: 0px !important;
  .mat-menu-item {
    border-bottom: 1px solid gainsboro;
    font-family: Cairo !important;
    font-size: 16px;
    padding: 0px !important;
    max-height: 40px !important;
    margin: 0px !important;
    span {
      position: absolute;
      right: 40px !important;
    }
    i.fa-trash,
    i.fa-list,
    i.fa-file-excel,
    .fa-clock {
      margin-right: 10px !important;
    }
    i.fa-edit {
      margin-right: 5px !important;
    }
  }
}

.warning-message {
  padding: 20px 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 0px;
  p {
    text-align: justify;
    text-justify: inter-word;
    font-size: 16px;
  }
}
.input-group-prepend {
  :hover {
    color: $active-standard-fontcolor;
    cursor: pointer;
  }
}

.hover-green:hover {
  color: $active-standard-fontcolor !important;
  font-weight: bold;
}
@media print {
  .sidebar {
    display: none;
  }
}

.width-10 {
  width: 10% !important;
}
.width-15 {
  width: 15% !important;
}
.width-20 {
  width: 20% !important;
}
.width-30 {
  width: 30% !important;
}

.width-40 {
  width: 40% !important;
}

.workplace {
  height: 40px !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  background: $card-bg-color !important;
}

.mat-tree-view {
  border: 2px solid $card-border-color;
  border-radius: 35px 0px 35px 0px !important;
}
