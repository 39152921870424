table {
  tr {
    line-height: 40px !important;
    max-height: 40px !important;
    padding: 2px 2px !important;
    td {
      padding: 2px 5px !important;
      i {
        cursor: pointer;
      }
    }
    th {
      padding: 0px !important;
      padding-right: 30px !important;
      text-align: right !important;
    }
  }
}

.table-statistics {
  color: $lighten-standard-fontcolor;
  font-weight: 600;
  label {
    width: 90% !important;
  }
  ul {
    margin-top: 3px;
    list-style-type: square;
    padding-right: 0px !important;
    li {
      margin-bottom: 5px !important;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  span {
    color: $lighten-standard-fontcolor;
  }
  margin-bottom: 0px !important;
}
