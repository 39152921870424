$blue: #0062bd;
$indigo: #2d1582;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ee0000;
$orange: #fd7e14;
$yellow: #fed700;
$green: #a3d133;
$teal: #00c9a7;
$cyan: #34bcec;
$white: #fff;
$gray: #8c98a4;
$gray-dark: #343a40;
$primary: #a3d133;
$secondary: #77838f;
$success: #00c9a7;
$info: #00dffc;
$warning: #ffc107;
$danger: #de4437;
$light: #f8f9fa;
$dark: #333e48;
$cyan: #34bcec;
$sale: #df3737;
$black: #000000;
$green: #a3d133;
$red: #ee0000;
$gray-1: #f5f5f5;
$gray-2: #383838;
$gray-3: #eeeeee;
$gray-4: #e6e6e6;
$gray-5: #878787;
$gray-6: #848484;
$gray-7: #f9f9f9;
$gray-8: #7b8186;
$gray-9: #8f8f8f;
$gray-13: #f8f8f8;
$gray-14: #eaeaea;
$gray-15: #9d9c9c;
$gray-16: #606060;
$gray-17: #ecedf2;
$gray-18: #333e484d;
$white: #fff;
$indigo: #2d1582;
$gold: rgb(230, 190, 138);

$standard-fontcolor: $dark;
$active-standard-fontcolor: green;
$lighten-standard-fontcolor: lighten($standard-fontcolor, 10%);
$card-bg-color: darken($light, 5%);
$layout-bg-color: darken($light, 2%);
$bg-color: #f1f3f3;

$card-standard-color: #f8f9fa;
$card-bg-color: darken($card-standard-color, 2%);
$card-border-color: darken($card-standard-color, 5%);

.bg-creamy {
  background: $card-bg-color !important;
}

.lighten-font-color {
  color: $lighten-standard-fontcolor;
}
