$dark: #333e48;
$danger: #de4437;
$gray: #8c98a4;

$bg-color: #1e1e1e;
$header-bg-color: #1b1b1b;
$blackboard-color: #333e48;
$blackboard-color: lighten($bg-color, 10%);
$title-light: #a3d133;
$title-light-transparent: rgba(0, 255, 0, 0);
$color-green: #a3d133;
$color-danger: #de4437;
$menu-width: 450px;
$item-width: 200px;
.file {
  visibility: hidden;
  position: absolute;
}

.btn.btn-primary {
  width: 150px;
  background-color: seagreen;

  outline: none;
  color: #fff;
  i {
    margin-top: 0px;
    padding-left: 10px;
  }
}

.icon-btn {
  width: 30px;
  height: 30px;
}

.close-container {
  .btn-close {
    i {
      color: $gray;
      margin-top: 8px;
      &:hover {
        color: $danger;
      }
    }
  }
}

.small-upload-btn {
  width: 50px !important;
}

.download-container {
  .row {
    line-height: 40px;

    border-radius: 20px 0px 20px 0px;
  }
  span {
    font-size: 15px;
    &.file-id {
      margin-left: 15px;
    }
  }

  i {
    padding-bottom: 3px;
  }
  .fa-trash {
    color: $gray;
    font-size: 18px;
    margin-top: 0px !important;
    &:hover {
      color: $danger;
      cursor: pointer;
    }
  }
  .fa-download,
  .fa-file-pdf,
  .fa-file-image {
    color: seagreen;
    margin-top: 8px;
    font-size: 18px;
    &:hover {
      color: $gray;
      cursor: pointer;
    }
    .fa-file-pdf,
    .fa-file-image {
      padding-top: 15px !important;
    }
  }
}

.header {
  line-height: 40px;
  margin: 0;
  padding: 0;

  h4 {
    margin: 0px;
    color: inherit;
    span {
      font-size: 15px;
      line-height: 35px;
    }
    i {
      color: $gray;
      padding: 0px 10px;
    }
  }
  .btn-upload-download {
    color: $gray;
    &:hover {
      color: seagreen;
      cursor: pointer;
    }
  }
}

.image-drag-drop {
  border: 2px dashed red;
  border-radius: 10px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  background: darken($card-bg-color, 3%);
  img {
    display: block;
    position: absolute;
    min-height: 100%;
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
.url {
  border: 0px solid $lighten-standard-fontcolor;
}

@media print {
  .files {
    visibility: hidden;
    display: none;
  }
  .file-title {
    border-bottom: 2px solid red !important;
  }
}

.stage {
  .header {
    line-height: 45px;
    margin: 0;
    padding: 0;
    background: darken($card-bg-color, 2%);
    .counter {
      font-family: 'Times New Roman', Times, serif;
      font-size: 14px;
    }

    h4 {
      margin: 0px;
      color: inherit;
      span {
        font-size: 16px;
      }
      i {
        color: $gray;
        padding: 0px 10px;
      }
    }
  }

  .remove-stage {
    color: $gray;
    font-size: 20px;
    margin-top: 28px !important;
    &:hover {
      color: $danger;
      cursor: pointer;
    }
  }
}
