@import '@angular/material/theming';

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

// NobleUI styles for LTR
//@import "./assets/scss/style";

// NobleUI styles For RTL
// @import "./assets/scss/style.rtl.css";

@import './assets/css/scss/theme.scss';

// regular style toast
@import 'ngx-toastr/toastr';

.cairo {
  font-family: Cairo !important;
}
h5,
h4 {
  font-size: 20px !important;
  font-family: Cairo !important;
}
