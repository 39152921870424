$bg-color: #f1f3f3;
$header-bg-color: #1b1b1b;
$title-color: #f8f9fa;
$footer-color: #f8f9fa;
$title-light: #74e035;

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  perspective: 1500px;
  font-family: Cairo;
  overflow-x: hidden; /* Add this line */
}

.sidebar {
  min-width: 292px;
  max-width: 292px;

  background: $layout-bg-color;
  border-left: 2px solid lighten(black, 60%);
  color: $standard-fontcolor;
  transition: all 0.5s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  transform-origin: bottom right;
  ul {
    li {
      color: $dark;
      line-height: 40px;
      border-bottom: 1px solid black;
      font-size: 16px;
      font-weight: 500;
      transition: all 0.2s ease;
      a {
        position: relative;
        width: 100%;
        text-decoration: none;
        padding-left: 20px;
        display: block;
        transition: all 0.2s ease;
        cursor: pointer;
        font-size: 17px;

        &:hover {
          padding-right: 20px;
          color: $active-standard-fontcolor !important;
        }
        i {
          color: $lighten-standard-fontcolor !important;
        }
      }
      &:last-child {
        border-bottom: 1px solid black;
      }
    }
  }
}
.right-sidebar {
  width: 292px !important;
  &.active {
    margin-right: -292px;
    width: 0px !important;
  }

  .close-sidebar-button {
    display: none;
  }
}
.left-sidebar {
  width: 292px !important;
  &.active {
    margin-left: -300px;
    width: 0px !important;
  }
  .close-sidebar-button {
    display: none;
  }
}

.contents {
  width: 100%;
  min-height: 70vh;
  transition: all 0.1s;
  flex-grow: 1;
  z-index: 1;
}

/* For smaller screens */
@media (max-width: 768px) {
  .right-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    z-index: 2;
    margin-right: -292px;
  }
  .left-sidebar {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    margin-left: -292px;
  }

  .right-sidebar.active {
    margin-right: 0;
    .close-sidebar-button {
      display: block;
      position: absolute;
      top: 5px;
      left: -40px;
    }
  }

  .left-sidebar.active {
    margin-left: 0px;

    .close-sidebar-button {
      display: block;
      position: absolute;
      top: 5px;
      right: -40px;
    }
  }

  .contents.blur {
    filter: blur(5px);
  }
}

.count {
  position: absolute;
  padding: 0px !important;
  left: 5px;
  top: 11px;
  width: 40px;
  height: 18px !important;
  line-height: 18px !important;

  background-image: linear-gradient($card-bg-color, darken($light, 3%));
  border: 2px solid darken($card-border-color, 15%);
  font-family: 'Times New Roman', Times, serif;
  color: $secondary;
}

.sidebar-tree-menue {
  position: relative;
  .dropdown-toggle::after {
    display: block;
    position: absolute;
    font-size: 22px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: $secondary;
  }
  ul {
    position: relative;
    li {
      box-sizing: border-box;
      transition: all 0.2s ease;
      border-top: 1px solid $secondary;

      a {
        padding-right: 40px;
        position: relative;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        display: block;
        width: 100%;
        border-left: 3px solid transparent;
        cursor: pointer;
      }

      &:first-child {
        border-top: 0px;
      }
      border-bottom: 0px !important;

      ul {
        position: static;

        li {
          line-height: 34px;
          position: relative;

          font-size: 17px;
          padding-right: 40px;
          cursor: pointer;
          box-sizing: border-box;

          font-family: 'Open Sans', sans-serif;
          transition: all 0.2s ease;
          border: none !important;

          &:hover {
            background: white;
            color: $active-standard-fontcolor;
          }

          .drag-handle {
            cursor: move;
            width: 30px;
            height: 30px;
            color: $lighten-standard-fontcolor;
            :hover {
              color: green !important;
            }
          }
          .checked-item {
            font-size: 23px !important;
            color: seagreen;
            padding-left: 10px;
            margin-top: 5px;
            border-radius: 0px !important;
          }
        }
      }
    }
  }
}

.checked-item {
  font-size: 23px !important;
  color: seagreen;

  border-radius: 0px !important;
}
