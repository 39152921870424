@charset "UTF-8";

@font-face {
  font-family: 'font-electro';
  src: url('../fonts/font-electro.eot');
  src:
    url('../fonts/font-electro.eot?#iefix') format('embedded-opentype'),
    url('../fonts/font-electro.woff') format('woff'),
    url('../fonts/font-electro.ttf') format('truetype'),
    url('../fonts/font-electro.svg#font-electro') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'font-electro' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'font-electro' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ec {
  display: inline-block;
  font: normal normal normal 14px/1 font-electro;
  font-size: inherit;
  text-rendering: auto;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ec-menu:before {
  content: '\61';
}
.ec-list-view-medium:before {
  content: '\62';
}
.ec-transport:before {
  content: '\63';
}
.ec-add-to-cart:before {
  content: '\64';
}
.ec-arrow-right-categproes:before {
  content: '\65';
}
.ec-comment:before {
  content: '\66';
}
.ec-compare:before {
  content: '\67';
}
.ec-customers:before {
  content: '\68';
}
.ec-dollar:before {
  content: '\69';
}
.ec-favorites:before {
  content: '\6a';
}
.ec-grid-view:before {
  content: '\6b';
}
.ec-list-view:before {
  content: '\6c';
}
.ec-blog-likes:before {
  content: '\6d';
}
.ec-phone:before {
  content: '\6e';
}
.ec-returning:before {
  content: '\6f';
}
.ec-search:before {
  content: '\70';
}
.ec-shopping-bag:before {
  content: '\71';
}
.ec-support:before {
  content: '\72';
}
.ec-tag:before {
  content: '\73';
}
.ec-thing-arrow-right:before {
  content: '\74';
}
.ec-user:before {
  content: '\75';
}
.ec-category-icon:before {
  content: '\76';
}
.ec-mail:before {
  content: '\77';
}
.ec-map-pointer:before {
  content: '\78';
}
.ec-newsletter:before {
  content: '\79';
}
.ec-close-remove:before {
  content: '\7a';
}
.ec-payment:before {
  content: '\41';
}
.ec-arrow-down-search:before {
  content: '\42';
}
.ec-list-view-small:before {
  content: '\43';
}
.ec-cameras:before {
  content: '\44';
}
.ec-drone:before {
  content: '\45';
}
.ec-gamepad:before {
  content: '\46';
}
.ec-headphones:before {
  content: '\47';
}
.ec-laptop:before {
  content: '\48';
}
.ec-printer:before {
  content: '\49';
}
.ec-smartphones:before {
  content: '\4a';
}
.ec-smartwatch:before {
  content: '\4b';
}
.ec-speaker:before {
  content: '\4c';
}
.ec-tvs:before {
  content: '\4d';
}
