.document-container {
  .row {
    margin: 0px !important;
  }
}
.official-documents {
  .card-body {
    padding: 10px 0px !important;
  }
}

.textbox-block {
  display: block;
  height: 25px;
  padding: 6px 16px;
  width: 100%;
  background: transparent;
}
.font-bold {
  font-weight: 600;
}
.textbox-long {
  display: inline !important;
  height: 20px !important ;
  padding: 0px 5px;
  width: 250px;
  background: transparent;
  margin: 0px !important;
  border: none;
  border-bottom: 1px dashed lightgray;
}

.textbox-inline {
  display: inline !important;
  height: 20px !important ;
  padding: 0px 5px;
  width: 100px;
  background: transparent;
  margin: 0px !important;
  font-weight: 600;
  border: none;
  border-bottom: 1px dashed lightgray;
}

.info {
  margin: 0px 0px !important;
  padding: 0px !important;
  height: 25px !important;
  background: none;
  span {
    margin: 0px 5px;
  }
  display: flex;
  gap: 8px;
}

.info-label {
  width: 120px !important;
  font-weight: 600;
}
.info-label-150 {
  width: 170px !important;
  font-weight: 600;
}
.textbox-200 {
  width: 200px !important;
}
.textbox-30 {
  width: 43px !important;
}

.info-label-70 {
  width: 90px !important;
  font-weight: 600;
}

.small-info {
  margin: 0px 0px !important;
  padding: 0px !important;
  height: 20px !important;
  background: none;
}
.small-textbox {
  height: 18px !important;
  display: inline !important;
  padding: 0px 5px;
  width: 100px;
  background: transparent;
  margin: 0px !important;
  font-weight: 600;
  border: none;
  border-bottom: 1px dashed lightgray;
}

.add-family-member {
  position: relative;
  top: -8px;
  color: $secondary;
  font-weight: 400;
  :hover {
    color: green;
  }
}
.remove-family-member {
  position: relative;
  top: -8px;
  color: $secondary;
  font-weight: 400 !important;
  :hover {
    color: $danger;
  }
}

.ration-issue-date {
  position: absolute;
  bottom: 50px !important;
  right: 40%;
  width: auto;
}

@media (max-width: 778px) {
  .textbox-long {
    width: 50% !important;
  }
  .textbox-200 {
    width: 50% !important;
  }
  .width-sm-40 {
    width: 45% !important;
  }
}
