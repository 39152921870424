$light: #f8f9fa;
$bg-header: darken($light, 2%);
.bg-header {
  background: $bg-header;

  .user-name {
    font-size: 19px !important;
  }

  .dropdown,
  .dropdown-menu {
    .dropdown-item {
      min-height: 300px;
      min-height: 30px !important;
      line-height: 30px;
      font-size: 16px;
      &:hover {
        cursor: pointer !important;
      }
    }
  }
}
.logo-university {
  height: 130px !important;
  width: 130px !important;
}
.logo-ministry {
  height: 130px;
  width: 230px;
}

.user-photo {
  height: 35px;
  width: 35px;
}

.document-image {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

#sidebarCollapse {
  width: 40px;
  position: static;
  :hover {
    cursor: pointer;
  }

  i {
    position: absolute;
    right: 20px;
    top: 12px;
    transition: all 0.8s ease-in;
    transition-delay: 0.1s;

    &.hidden {
      opacity: 0;
    }
  }
}

.page-header-info {
  margin: 1px 20px !important;
  padding: 0px 0px;
  line-height: 45px !important;
  min-height: 45px !important;
  //background: linear-gradient($card-bg-color, darken($light, 6%));
  background: $bg-header;
  border: 1px darken($card-border-color, 5%) solid;
  border-radius: 30px;

  .info-contents {
    width: 75% !important;
  }

  h4 {
    line-height: 45px !important;
    min-height: 45px !important;
    margin: 0px;
    font-weight: 600;
    font-size: 16px !important;
  }
  .btn i {
    &:hover {
      color: $active-standard-fontcolor !important;

      transition: linear 0.1s;
    }
  }
}

@media (max-width: 778px) {
  .page-header-info {
    margin: 0px -3px !important;
    padding: 0px;
    border-radius: 0px 0px 25px 25px;
  }
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .content-container {
    padding: 0px 3px;
  }
}

@media print {
  .topheader {
    margin-left: 40px !important;
    margin-right: 40px !important;
    border: 1px solid black !important;
    header {
      border: 0px !important;
      padding-bottom: 10px !important ;
    }
  }
  .page-header-info {
    margin: 0px -3px !important;
    padding: 0px;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid black;
    border-top: 0px;

    .info-contents {
      width: 100% !important;
    }
  }
}
